import { useHistory, useParams } from "react-router-dom";

import useFetch from "./store/useFetch";
import ThreeCanvas from "./ThreeCanvas";


import SideBar from "./Components/Sidebar/SideBar";

//import  MyContext from "./store/MyContext";
const Create = () => { 
  //const myCtx=useContext(MyContext)
  
  const { id } = useParams();
  const history = useHistory();



const { error, isPending, data} = useFetch('https://areteng.com/api/Ass/'+id)


//data &&  myCtx.updatemeshsArry(data.uniAssembly)
console.log(data)


  return (
    <div className="Home">
      {error && <div>{error}</div>}
      {isPending && <div>Loading...</div>}
   
      {data &&  <div className="Canvas"> 
     
       {/*     <SidebarMenu  meshsArry={data.partInsts} Assy={data.uniAssembly} go={go} />*/  } 
         
       
   <SideBar meshsArry={data.partInsts} uniAssembly={data.uniAssembly} Assy={data.parentAss} /> 
  
          <ThreeCanvas
            meshsArry={data.partInsts} 
            Assy={data.parentAss}
     
         
          />
        

        </div>
      }
    </div>
  );
};

export default Create;

import React from "react";

import SidebarItem from "./SidebarItem";
import { useHistory,Link } from "react-router-dom";
import SelectedItem from "./SelectedItem";
import SidebarContent from "./SidebarContent";
import SidebarDatum from "./SidebarDatum";
import MyContext,{root} from "../../store/MyContext";
import {  useContext } from "react";
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
const SideBar= ({ meshsArry, Assy, uniAssembly ,datum }) =>{
  const history = useHistory();
  
  const myCtx = useContext(MyContext);






  return (
    <>
      
      <input type="checkbox" name="" id="side-menu-switch" />
      <span className="side-menu">
        
        <label htmlFor="side-menu-switch">
        <img src={root+'/images/arrow.png' }  alt="arrow" style={{ width:'100%',verticalAlign:'middle'}}  ></img>
        </label>
            {myCtx.selectSt == null ? (
<>
             {(Assy!==null)&&(<><div className="parent" onClick={()=>history.push("/?uniAssembly=" + Assy.id)}> {Assy.name} <ArrowCircleUpIcon /></div>
          
          
             <div className="branch"/></>)}
          <SidebarContent uniAssembly={uniAssembly} meshsArry={meshsArry} />

<div className="partslist">
               { myCtx.datumSt!==null && <SidebarDatum Item={datum[myCtx.datumSt]} key={myCtx.datumSt}  uniAssemblyID={uniAssembly.id}  ></SidebarDatum>}  
     {  meshsArry.map((Item, indix) => (<SidebarItem Item={Item} key={indix} onClick={()=>myCtx.setSelectSt(indix)} > </SidebarItem>))}
     
     
        </div>
        </>) : (<>

         
          <div className="parent" onClick={()=>myCtx.setSelectSt(null)}> {uniAssembly.name}</div>
        
          <div className="branch" ></div>

        <div className="partslist" > 
        { meshsArry.map((Item, indix) => (indix === myCtx.selectSt ?< SelectedItem Item={meshsArry[myCtx.selectSt]} key={indix} uniAssemblyID={uniAssembly.id}></SelectedItem>: <SidebarItem Item={Item} key={indix} onClick={()=>myCtx.setSelectSt(indix)}/>))}
            
            </div>
            </>)}
     
      </span>

    

    </>
  );
}

export default SideBar;

import Navbar from './Navbar';
import Home from './Home';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Create from './Create';
import Details from './Details';
import NotFound from './NotFound';
import {MyContextProvider, MyStatusProvider} from './store/MyContext'
import LogIn from './LogIn';
import About from './About';

function App() {

  return (
<MyContextProvider>

    <Router>
      <div className="App">
        <MyStatusProvider>
        <Navbar />         
        {/* <div className="content">*/}
        </MyStatusProvider>
          <Switch>
          <Route exact path="/About" component={About}/>
            <Route exact path="/Home" component={Home}/>
            <Route exact path="/" component={Details}/>
            <Route exact path="/LogIn" component={LogIn}/>
            <Route path="/Create/:id">

              <Create />
            </Route>

            {/* <Route path="/:id">
              <Details />
              </Route> */}
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        
        </div>
      
    </Router>
    
    </MyContextProvider>

  );
}

export default App;



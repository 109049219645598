import { useHistory } from "react-router-dom";
import MyContext,{root} from "../../store/MyContext";
import {  useContext ,useState} from "react";
import { PLYExporter } from "three/examples/jsm/exporters/PLYExporter";
import * as BufferGeometryUtils from "three/examples/jsm/utils/BufferGeometryUtils.js";
import * as THREE from "three";
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined';
import {reduceForSave} from '../../Components/myMergeVertices'
const SelectedItem=({Item,uniAssemblyID})=> {
    const history = useHistory();
    const myCtx = useContext(MyContext);
    const [isLoading, setIsLoading] = useState(false);
if(myCtx.grabSt!==null && myCtx.grabSt.geometry!== undefined){
  Item.geometry=myCtx.grabSt.geometry
  Item.x=myCtx.grabSt.x
  Item.y=myCtx.grabSt.y
  Item.modelFile=myCtx.grabSt.modelFile
  Item.picFile=myCtx.grabSt.picFile
  Item.updated_at="not"
}

    return (
  <div disabled={isLoading} className="SeletedPart">
          {myCtx.isEditOn?(<>
          {Item.updated_at==="not" ? ( <>
         <form style={{margin:"0px",padding:"2px 0px ", borderRadius:"10px"}} ><input type="text" defaultValue={Item.name} onChange={(e) =>{Item.name=e.target.value}} 
            style={{color:'orange','fontStyle':'italic'}}/></form>

        <div   style={{right:200}} onClick={() =>{
        Item.created_at==="not"?creatInst(Item,uniAssemblyID,setIsLoading):savePart(Item,setIsLoading);
        setIsLoading(true) }} 

        > {isLoading?<HourglassTopIcon className="itemWidget" style={{right:210}}/>:
        <SaveIcon className="itemWidget" style={{right:200}}/>}</div>

           </> ):(
        <form style={{margin:"0px",padding:"2px 0px ", borderRadius:"10px"}}><input type="text" defaultValue={Item.name} onChange={(e) =>{Item.name=e.target.value;Item.updated_at="not"}} 
        style={{color:'black'}}/></form>
            )}
         <ContentCutIcon className="itemWidget"  style={{right:230}} onClick={() =>{myCtx.setClipeBoard(Item);cutPart(Item,myCtx)}} />
        <ClearIcon className="itemWidget"  style={{right:260, backgroundColor:"red"}} onClick={() =>{cutPart(Item,myCtx)}} />

        </>):(<>



          
          
<div className="title"style={Item.updated_at==="not" ? {color:'orange','fontStyle':'italic'}:{overflow: 'hidden'} }>{Item.name} </div>
<div className="content" style={Item.updated_at==="not" ? {color:'orange','fontStyle':'italic'}:{overflow: 'hidden'} }>
<div >Part Number:{Item.id}:{Item.id} </div>
<div className="date"  >{Item.updated_at==="not"?"Not Saved!" :new Date(Item.updated_at).toISOString().split('T')[0]} </div>
<div className="author" >Theodros Galt</div>
<div className="rating" > $  </div>

</div>
          
          
          </>
          
          
          
          
          )}
          {/* <PrecisionManufacturingOutlinedIcon className="itemWidget"  style={{right:170}} onClick={() =>{myCtx.setClipeBoard(Item);myCtx.geoUpdate()}} /> */}
          
          <img src={root+'/images/hook.svg' }  className="itemWidget" alt="hook"  style={{right:170,padding:2}} onClick={() =>{myCtx.setClipeBoard(Item);myCtx.geoUpdate()}} /> 

          <img src={root+'/images/'+Item.picFile }  className="linkImages"  onError={imageOnErrorHandler}  alt="linkImages" onClick={() => {
            
            myCtx.isEditOn?myCtx.setGrabSt("request"):history.push("/?uniAssembly="+Item.id);
            
            
            }} /> 


    </div>
    )
}
export default SelectedItem


//reduceForSave()
function saveGeometry(part,setIsLoading) {
 
    var exporter = new PLYExporter();
    var result = exporter.parse(
      new THREE.Mesh(reduceForSave(part.geometry))
    ,(e) => console.log(e.length), { binary: false});
    var file = new File(
      [new Blob([result], { type: "text/plain" })],
      "ya.txt"
    );
    var data = new FormData();
    data.append("File", file);
    data.append("Name", "boxIIY.PLY");
    data.append("UniAssemblyID", 1026);

    fetch(root+'/api/uploadModel/'+part.id, {
      method: "POST",
      body: data,
    }).then((res) => {
      if (!res.ok) {throw Error("can't create Model");}
      return res.json();
    }).then((d) => {part.modelFile=d.modelFile;console.log(part.updated_at);setIsLoading(false)})
    .catch((err) => { console.log(err.message) });
   
  // }).then((res) => {
  //   console.log(res);
  // });
    
  
}
function savePart(part,setIsLoading){
    const partInst = {...part}
    delete partInst.geometry;
    delete partInst.updated_at;
    delete partInst.created_at;
    delete partInst.id;
    //delete partInst.modelFile;
    
    //const token = auth?.token;
    fetch(root+"/api/savePart/" + part.id,{
        method: "PUT",
        headers: { "Content-Type": "application/json"
      // , 'Authorization': `Bearer ${token}`,
       },
        body: JSON.stringify(partInst),
      }).then((res) => {
        if (!res.ok) {throw Error("can't updat part");}
        return res.json();
      }).then((data) => {
        part.updated_at=data.updated_at;
        part.modelFile==null ? saveGeometry(part,setIsLoading):setIsLoading(false) })
    .catch((err) => { console.log(err.message) });
  

}

function creatInst(part,uniAssemblyID,setIsLoading){

  const partInst = {...part}
  delete partInst.geometry;
  delete partInst.updated_at;
  delete partInst.created_at;
  delete partInst.id;
  //delete partInst.modelFile;
  partInst.uniAssemblyID=uniAssemblyID

  fetch(root+"/api/createInst",{
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(partInst),
    }).then((res) => {
      if (!res.ok) {throw Error("can't create part");}
      return res.json();
    }).then((data) => {part.id=data.id;
      part.updated_at=data.updated_at;
      part.created_at=data.created_at;
 
     part.modelFile==null ? saveGeometry(part,setIsLoading):setIsLoading(false) })
    .catch((err) => { console.log(err.message) });
  

}

function cutPart(part,myCtx){
 
  let text = "Delete the item permanently ?\nEither OK or Cancel.";
  if (window.confirm(text)) {
  part.uniAssemblyID=1000
  
 // part.created_at="not";
      
  const partInst = {...part}
  delete partInst.geometry;
  delete partInst.updated_at;
  delete partInst.created_at;
  delete partInst.id;
  delete partInst.modelFile;
  

  fetch(root+"/api/savePart/" + part.id,{
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(partInst),
    }).then((res) => {
      if (!res.ok) {throw Error("can't updat part");}
      return res.json();
    }).then((data) => {

      })
  .catch((err) => { console.log(err.message) });

  destroy() 


function destroy(){
  if (part.created_at!=="not"){
  fetch(root+'/api/destroy/'+part.id, 
  { method: 'DELETE' })
  .then((res) => {
    if (!res.ok) {throw Error("can't delet part");}
    return res.json();
  }).then(() => {
   
}).catch((err) => { console.log(err.message) });

}
part.created_at="delete"
myCtx.setSelectSt(null)

}

}}



function imageOnErrorHandler(e){
  e.currentTarget.src = ('./icon/Fallback.svg')
 } 


import * as THREE from "three";
import {  useThree ,useFrame } from "@react-three/fiber";
import React, { useContext, useRef,useState,useEffect,forwardRef,Suspense} from "react";


 const ZoomFit = React.forwardRef(({  start=()=>{}, end=()=>{} },ref) =>{
    const { camera ,gl } = useThree();
    
    
    //const [update,setUpdate]=useState(true ) ;
    
const [postObj,setPostObj]=useState({pos:new THREE.Vector3(0,0,0),center:new THREE.Vector3(0,0,0),target:new THREE.Vector3(0,0,0),update:null} ) 


    useEffect(
        () => {
            ref.current={Update:()=>{},anime:()=>{}}
            ref.current.Update=function( controls, selection, fitOffset){

      
    if(selection?.geometry ){ 
        !postObj.update && start();

        //const center = selection.geometry.boundingSphere.center;
        //let center = new THREE.Vector3(selection.x,selection.y,selection.z)
        let center
        if (selection.position ===undefined){
             center= selection.geometry.boundingSphere.center}
        else { center=selection.position}
    
        const maxSize =selection.geometry.boundingSphere.radius*2 ;
        const fitHeightDistance = maxSize / (2 * Math.atan((Math.PI * camera.fov) / 360));
        const fitWidthDistance = fitHeightDistance / camera.aspect;
        const distance = fitOffset * Math.max(fitHeightDistance, fitWidthDistance);
        
        const direction = controls.target
          .clone()
          .sub(camera.position)
          .normalize()
          .multiplyScalar(distance);
          controls.maxDistance = distance * 20;
          camera.near = distance / 20;
        camera.far = distance * 20;
        const pos =new THREE.Vector3().copy(center).sub(direction);
        //start();
        setPostObj({pos:pos,center:center,target:controls.target,update:1})
        }
                  }
  })

    
  
    useFrame((state) => {
if(postObj.update){

    postObj.update+=1
    state.camera.lookAt(postObj.target.lerp(postObj.center,.2));
    state.camera.position.lerp(postObj.pos,.2);
    state.camera.updateProjectionMatrix();
    
    if (postObj.update>12){
        state.camera.lookAt(postObj.center);
        state.camera.position.copy(postObj.pos);
        state.camera.updateProjectionMatrix();
        
        
        postObj.update=null;end();} ;
   }
   return null;

        
    })

  return null
})

  export default  ZoomFit
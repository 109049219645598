import {  useContext ,useEffect,useState} from "react";
import { PLYLoader } from "three/examples/jsm/loaders/PLYLoader";
import  {MyStatus,MyStatusProvider,root}from "./MyContext";
import {mergeVertices,getNormal} from '../Components/myMergeVertices'
import * as THREE from "three";

const LoadGeometry=(data,myCtx) =>  {
  
  
    
    

    
    data.uniAssembly.x??(data.uniAssembly.x=0)
    data.uniAssembly.y??(data.uniAssembly.y=0)
    data.uniAssembly.z??(data.uniAssembly.z=0)
   
  
    myCtx.setCenter([data.uniAssembly.x,data.uniAssembly.y,data.uniAssembly.z]);
    
    console.log("Geometry loading ... ");
    console.log("...file");
    let k = 0;
    var d;
   
    
    let bBox={ max:{x:-1000,y:-1000,z:-1000},min:{x:1000,y:1000,z:1000}}
     let center =new THREE.Vector3()
  
    //if (data.uniAssembly.modelFile==null) data.uniAssembly.modelFile=data.uniAssembly.name + '.ply';
   // if (data.parentAss.modelFile==null) data.parentAss.modelFile=data.parentAss.name + '.ply';
    let count=0
  
    data.partInsts.forEach((meshIns, index) =>
       {
  
         if (meshIns.modelFile==null) meshIns.modelFile=meshIns.name + '.ply';
        console.log(meshIns.modelFile);
        void new PLYLoader().load(root+'/PLY/' + meshIns.modelFile, AfterLoad,()=>{},);
        function AfterLoad(geome) {
            count++;
          if (geome === undefined || geome == null)geome = new THREE.BufferGeometry();
         
            geome=getNormal(geome)
            geome = mergeVertices(geome);
            geome.computeBoundingSphere();
            geome.computeBoundingBox ();
         //   geome.computeVertexNormals();
           const a = geome.boundingSphere.center;
  
            meshIns.x==null?meshIns.x = a.x:a.x=0;
            meshIns.y==null?meshIns.y = a.y:a.y=0;
            meshIns.z==null?meshIns.z = a.z:a.z=0;
            geome.translate(-a.x, -a.y, -a.z);
  
     let max=geome.boundingBox.max;
     let min=geome.boundingBox.min;
  
         (bBox.max.x < (max.x+meshIns.x))&&(bBox.max.x=max.x+meshIns.x); 
         (bBox.max.y < (max.y+meshIns.y))&&(bBox.max.y=max.y+meshIns.y);  
         (bBox.max.z < (max.z+meshIns.z))&&(bBox.max.z=max.z+meshIns.z);  
  
         (bBox.min.x > (min.x+meshIns.x))&&(bBox.min.x=min.x+meshIns.x); 
         (bBox.min.y > (min.y+meshIns.y))&&(bBox.min.y=min.y+meshIns.y);  
         (bBox.min.z > (min.z+meshIns.z))&&(bBox.min.z=min.z+meshIns.z); 
         center.set(bBox.max.x+bBox.min.x,
                    bBox.max.y+bBox.min.y,
                    bBox.max.z+bBox.min.z).divideScalar(2)
          
  
            geome.key = index;
            
            meshIns.geometry = geome;
  
            const color =new THREE.Color((Math.random()*50+90)/250,(Math.random()*50+90)/250,(Math.random()*50+90)/250);
            color.add(new THREE.Color( 0xff0000 ));

            (meshIns.color==null||meshIns.color===16777215) && (meshIns.color=color.getHex() )
            console.log("Geometry " + index + " loded");
  
            if (k < geome.boundingSphere.radius) {
              k = geome.boundingSphere.radius;
              myCtx.setRadius(geome.boundingSphere.radius);
              myCtx.setBig(index);
              
            }
            
         //   if (count===data.partInsts.length){ 
              // myCtx.setRadius(  Math.sqrt((( bBox.max.x-bBox.min.x)*( bBox.max.x-bBox.min.x))+
              //                             (( bBox.max.y-bBox.min.y)*( bBox.max.y-bBox.min.y))+
              //                             (( bBox.max.z-bBox.min.z)*( bBox.max.z-bBox.min.z)))/2)
              const r=Math.max((bBox.max.x-bBox.min.x),(bBox.max.y-bBox.min.y),(bBox.max.z-bBox.min.z))/2
              myCtx.setRadius(r)
             // Set_st(data.partInsts,r)
            // data.uniAssembly.geometry.boundingSphere.radius=myCtx.getRadius()
             data.uniAssembly.geometry={boundingSphere:{radius:myCtx.getRadius(),center:center}} 
             
                    
                                       
   myCtx.geoUpdate()
//};
          
          //   setLoaded(index)
          //  setSelectSt(null)
        }
       
          }
        );
         
        if(data.partInsts.length===0){d=data.uniAssembly }else d=data.parentAss;
    
      if (d!==null){
       
        void new PLYLoader().load( root+'/PLY/'  + d.modelFile,onLoad,()=>{},onError);}
          
      function onLoad(geome) {
            console.log("S")
            if (geome !== undefined && geome !== null) {
              geome=getNormal(geome)
              geome = mergeVertices(geome);
              //geome.computeVertexNormals();

              geome.computeBoundingSphere();
              if(data.partInsts.length===0){myCtx.setRadius(geome.boundingSphere.radius)}
              geome.key = d.id;
              d.geometry = geome;

              const color =new THREE.Color((Math.random()*50+90)/250,(Math.random()*50+90)/250,(Math.random()*50+90)/250);
              color.add(new THREE.Color( 0xff0000 ));
  
              (d.color==null||d.color===16777215) && (d.color=color.getHex() )
              console.log("Geometry of Assembly loded");
            }
           
            
            console.log("All Geometry loded");
            myCtx.geoUpdate();
          }

          function  onError(){

            d.x??(d.x=0); d.y??(d.y=0);d.z??(d.z=0)
            
            d.geometry= new THREE.BufferGeometry();
            const vertices = new Float32Array( [
              -.01, -.01,  .01,
               .01, -.01,  .01,
               .01,  .01,  .01,
            
               .01,  .01,  .01,
              -.01,  .01,  .01,
              -.01, -.01,  .01
            ] );
            d.geometry.setAttribute( 'position', new THREE.BufferAttribute( vertices, 3 ) );
            d.geometry.computeBoundingSphere();
          
            console.log("new Geometry created");
   
            myCtx.geoUpdate();
          }
       
      
  }
  export default LoadGeometry;
  function Set_st(meshsArry, r) {

    let aver = new THREE.Vector3(0,0,0);
    meshsArry.forEach((o) => {
      aver.x= aver.x+o.x
      aver.y= aver.y+o.y
      aver.z= aver.z+o.z
    })
    aver=aver.divideScalar(meshsArry.length);

    meshsArry.forEach((o) => {

      if (
        o.x_st == null &&
        o.y_st == null &&
       o.z_st == null 
      
      ) 
      {
        
        let pos = new THREE.Vector3(o.x,o.y,o.z);
        pos=pos.subVectors(aver,pos).normalize().multiplyScalar(r);
   
        o.x_st = pos.x;
        o.y_st = pos.y;
       o.z_st = pos.z;
      }
    });
  }
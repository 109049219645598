import React from 'react'
import './logIn.css';
function About() {

  return (
    <div className="Modal"  style={{padding:"30px"}}>
    <div></div>
    Hello there <div> :) </div>  <div></div> I am Ted ,This page is under construction by me , it is kind of 3D explore for parts and hardware by providing complete knock down of Assemblies as much as possible , 
 you can explore   exploded views and disassembly , it is also  possible to take parts and build new assemblies .few file importing options are also available  . <div></div>kinda cool ha ?
    <div></div>Hey ,if you like this please let me know at <div></div><div></div>  -- theodros@areteng.com-- <div>(416)569-3454 </div> <div>Toronto</div>.;-)
   

    </div>


  )
}

export default About
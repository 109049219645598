import { createContext ,useState} from 'react';

let clpBoard=[]
let Workbench=null 
let theRadius=0 ;
let theBigest=0;
let xpld=false;
let center=[0,0,0];
let search=null


const MyContext =createContext({
getRadius:()=>{},
setRadius:(p)=>{},
big:()=>{},
setBig:()=>{},
trGeoUpdate:0,
center:[0,0,0],
selectSt:null,
datumSt:null,
grabSt:null,
searchSt:search,
isEditOn:false,
isDatumOn:false,

setSearchSt:(searchSt)=>{},
setSelectSt:(selectSt)=>{},
setDatumSt:(datumSt)=>{},
setEditOn:(isEditOn)=>{},
setDatumOn:(isDatumOn)=>{},

setGrabSt:(grabSt)=>{},

geoUpdate:()=>{},
isexploded:()=>{},
setexplod:()=>{},
clipeBoard:[],
setClipeBoard:()=>{},
getClipeBoard:()=>{},
getAllClipeBoard:()=>{},
getSelectedClipeBoard:()=>{},
});



export function MyContextProvider(props){
  ///const [selectSt, setSelectSt] = useState(null);


  const [trGeoUpdateST, setTrGeoUpdateST] = useState(false);
  const [select,setSelected]=useState(null)
  const [datum,setDatum]=useState(null)
  //const [search,setSearch]=useState(null)
  const [isEdit,setEdit]=useState(false)
  const [isDatum,setDatumEdite]=useState(false)

  const [grab,setGrab]=useState(null)
function setRadiuser  (ex){theRadius=ex}
  function gettheRadius  (){return theRadius}


  function setBiger  (ex){theBigest=ex}
  function getBiger  (){return theBigest}

  function setcenter(e){center=e}
  function getCenter  (){return center}

  function explod(){xpld=!xpld;}
 
  function isexplod(){return  xpld;}

 //const [crAss, setCrAss] = useState(null);
  // function updatePartlistHandler(newPartlist){

  //   setPartList((p)=>{return newPartlist})
  // } 
  function updateSelectHandler(newSelec){
    setDatum(null)
    setSelected((p)=>{return newSelec})
  }  
  
  function searchHandler(sh){
    search=sh
    // setSearch((p)=>{return sh})
  }

  function getSearch(){return search}
  function setGrabStHandler(gr)
  {
    setGrab((p)=>{return gr})
  }

  function EditOnHandler(newT){

    setEdit((p)=>{return newT})
  }  

  function DatumOnHandler(newT){

    setDatumEdite((p)=>{return newT})
  } 
  function DatumHandler(newT){
    setSelected(null)
    setDatum((p)=>{return newT})
  }
  

  function  geomUpdaterHandler(){
    xpld=false;
    setTrGeoUpdateST((p)=>{return !p})
  }

  function setClipeBoard(p){ 
    clpBoard.push({...p})
    clpBoard[clpBoard.length-1].updated_at=null;
    clpBoard[clpBoard.length-1].created_at="not";
    clpBoard[clpBoard.length-1].origin===0 && (clpBoard[clpBoard.length-1].origin=clpBoard[clpBoard.length-1].id);
    
  }
  function getClipeBoard(p){ 
    if( clpBoard.length>0){
    clpBoard[clpBoard.length-1].uniAssemblyID=p.id
    clpBoard[clpBoard.length-1].updated_at= "not"
    return clpBoard[clpBoard.length-1]
    }
  }
  function getAllClipeBoard(){ 
    return clpBoard
  }
  function getSelectedClipeBoard(){ 
    return clpBoard.filter(e=>e.updated_at!==null)
  }
  const context ={
    
    getRadius:gettheRadius,
    setRadius:setRadiuser,
    big:getBiger,
    setBig:setBiger,
    setCenter:setcenter,
    center:getCenter,
    trGeoUpdate:trGeoUpdateST,
 
    selectSt:select,
    grabSt:grab,
    isEditOn:isEdit,
    isDatumOn:isDatum,
    datumSt:datum,
    searchSt:search, 
    getSearch:getSearch,
    setSelectSt:updateSelectHandler,
    setGrabSt:setGrabStHandler,
    setEditOn:EditOnHandler,
    setDatumOn:DatumOnHandler,
    setDatumSt:DatumHandler,
    setSearchSt:searchHandler,

    geoUpdate:geomUpdaterHandler,
    isexploded:isexplod,
    setexplod:explod,
    clipeBoard:clpBoard,
    setClipeBoard:setClipeBoard,
    getClipeBoard:getClipeBoard,
    getAllClipeBoard:getAllClipeBoard,
    getSelectedClipeBoard:getSelectedClipeBoard
  }


  return <MyContext.Provider value={context}>

   {props.children}
  </MyContext.Provider>

} 


const MyStatus =createContext({
  getStatus:()=>{},
  setStatus:()=>{},
})
export function MyStatusProvider(props){
  const [status, setstatusST] = useState(false);

  function setStatus(newST){

    setstatusST((p)=>{return newST})
  } 

 
  function getStatus(){return status}
  const context ={
  getStatus:getStatus,
  setStatus:setStatus
  }

  return <MyStatus.Provider value={context}>

   {props.children}
  </MyStatus.Provider>
}


 

export default MyContext;
//const root='http://localhost:8000';
const root=''; 
export  {root,MyStatus};
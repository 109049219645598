import MyContext ,{root} from "../../store/MyContext";
import { useContext } from "react";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";
import {mergeVertices,getNormal} from '../../Components/myMergeVertices'
import { PLYLoader } from "three/examples/jsm/loaders/PLYLoader";
import * as THREE from "three";
import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined';
import {mergeGeometry} from '../../Components/MakeHollow'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
function SidebarContent({uniAssembly,meshsArry}) {
  const myCtx = useContext(MyContext);
 
  var mtlUrl="a"
  var objUrl="a"
  var plyUrl=""
    return (
        <div >
        
     
          <div className="assembly" >
          <div className="name" >{uniAssembly.name}</div> 
              {/* <PrecisionManufacturingOutlinedIcon className="itemWidget"  style={{right:20}} onClick={() =>{geoLoad(uniAssembly,myCtx,meshsArry)}} />  */}
              <img src={root+'/images/hook.svg' }  className="itemWidget"   alt="NO"  style={{right:20,padding:2}} onClick={() =>{geoLoad(uniAssembly,myCtx,meshsArry)}} /> 
              <div >Part number:{uniAssembly.id}  </div>
              <div >Original:{uniAssembly.origin} </div>
              <div className="accout" ><AccountCircleIcon  fontSize="large"  />By: Theodros Galt </div>
              
             {myCtx.isEditOn && (<>
             
             <img src={uniAssembly.picFile}  alt="Assembly" className="Prvimages"  onError={imageOnErrorHandler}   /> 

             {/* <ContentPasteIcon className="itemWidget"  style={{right:20}} onClick={() =>{meshsArry.push({...myCtx.getClipeBoard(uniAssembly)});myCtx.geoUpdate()}} /> */}
             {/* <AttachFileIcon  style={{'right': '20px','position':'absolute','cursor': 'pointer'}}>  */}
         
         
       
        {/* </AttachFileIcon>  */}
        </>)}
        </div>
        
    <div className="branch"/>
       
         
        </div>
    )
}

export default SidebarContent




function geoLoad(uniAssembly,myCtx,meshsArry){
  
  const d ={...uniAssembly}
  delete d.geometry;
  (d.color==null||d.color===16777215) && (d.color= Math.random() * 0xffffff)

  void new PLYLoader().load( root+'/PLY/'  + d.modelFile,onLoad,()=>{},onError);
    


function onLoad(geome,) {
      console.log("S")
      
        geome=getNormal(geome)
        geome = mergeVertices(geome);
        //geome.computeVertexNormals();

        geome.computeBoundingSphere();
        const a = geome.boundingSphere.center;
  
            d.x==null?d.x = a.x:a.x=0;
            d.y==null?d.y = a.y:a.y=0;
            d.z==null?d.z = a.z:a.z=0;
            geome.translate(-a.x, -a.y, -a.z);


        d.geometry = geome; 
      console.log("Geometry of Assembly loded");
      myCtx.setClipeBoard(d);myCtx.geoUpdate()
    }
function  onError(){
  d.x??(d.x=0); d.y??(d.y=0);d.z??(d.z=0)
  
    let text = "rep Gometray can't bee found ,do you want to generat one ? it'll takes sometime though";
    if (window.confirm(text) == true) {
      console.log("You pressed OK!");
      d.geometry= mergeGeometry(meshsArry)

      d.geometry.computeBoundingSphere();
        const a =  d.geometry.boundingSphere.center;
  
            d.x==null?d.x = a.x:a.x=0;
            d.y==null?d.y = a.y:a.y=0;
            d.z==null?d.z = a.z:a.z=0;
            d.geometry.translate(-a.x, -a.y, -a.z);
      
      console.log("rep Gometray created");
      myCtx.setClipeBoard(d);myCtx.geoUpdate()

    } else {
     console.log("You canceled!");
   
  





  
  d.geometry= new THREE.BufferGeometry();
  const vertices = new Float32Array( [
    -.01, -.01,  .01,
     .01, -.01,  .01,
     .01,  .01,  .01,
  
     .01,  .01,  .01,
    -.01,  .01,  .01,
    -.01, -.01,  .01
  ] );
  d.geometry.setAttribute( 'position', new THREE.BufferAttribute( vertices, 3 ) );
  d.geometry.setAttribute( 'normal', new THREE.BufferAttribute( vertices, 3 ) );
  d.geometry.computeBoundingSphere();

  console.log("new Geometry created");
  myCtx.setClipeBoard(d);myCtx.geoUpdate()
  
}

}

    }




    
    function imageOnErrorHandler(e){
      e.currentTarget.src = ('./icon/Fallback.svg')
     } 
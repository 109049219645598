import { Link  ,useHistory} from "react-router-dom";
import useFetch from "./store/useFetch";
import Upload from "./store/Upload";
import MyContext, {root} from "./store/MyContext";
import React,{  useContext ,useEffect,useState,useRef} from "react";

import HomeIcon from '@mui/icons-material/Home';

const Navbar = () => {
const myCtx = useContext(MyContext);

 // const[open,setOpen]=useState(false)
    const ref= useRef();
    const inputRef = useRef(null)
    const searchRef = useRef()
    const spanRef = useRef(null)
    const history = useHistory();
  
    useEffect(() => {  
     
    if (myCtx.grabSt==="request" && myCtx.isEditOn){
     // inputRef.current.checked=;
     if (spanRef.current.offsetLeft!==-320){spanRef.current.style.left="170px"}else
     {spanRef.current.style.left="-170px"}
       }else{

       }
     if (myCtx.getSearch()==null || myCtx.grabSt==="checkbox"){

      inputRef.current.checked=true;
     }else {inputRef.current.checked=false}
      
      }
      )


      
      
  
    
    
    
function open () {
  ref.current.Update()
  inputRef.current.checked=true;
      }

      



  function onClick (e){
    inputRef.current.checked=(spanRef.current.offsetLeft!==-320);
    history.push("/?uniAssembly=" + e)}

    function onSelect (item){
if (myCtx.selectSt!==null && myCtx.grabSt==="request" && myCtx.isEditOn)
      {
        //inputRef.current.checked=(spanRef.current.offsetLeft!==-320);
        spanRef.current.style.left="" 
        myCtx.setGrabSt(item)
      }else{
        inputRef.current.checked=(spanRef.current.offsetLeft!==-320);
    history.push("/?uniAssembly=" + item.id)}
      }

    
    

    //inputRef.current.checked=false
                return (
    <>
    <Link to="/LogIn" ><button className="signIn" >Sign in</button></Link>
    <div className="search">
  
     <form  className="form" onSubmit ={ (e) =>{e.preventDefault()}}
    >  
      <input type="text" onFocus={(e)=>console.log(spanRef)} ref={searchRef} placeholder="Search For"  />
      
     <button  onClick={(e)=>{myCtx.setSearchSt(searchRef.current.value);console.log(searchRef.current.value);open()}} >

          </button>
    </form>     
 

        
           <Upload/>
           <div className="dropdown">
<HomeIcon className="dropbtn" />
<div className="dropdown-content">
<Link to="/Home" >Home</Link>
<Link to="/Home" >Features</Link>
<Link to="/About" >About </Link>

</div>
</div>

    </div>
    <div >
       <input type="checkbox" ref={inputRef}   
        // checked={false}   onChange={() => {}} 
       name="" id="side-menu-switch2" />
      <span className="navbar-menu" ref={spanRef}  onMouseLeave={(e)=>{spanRef.current.style.left="";myCtx.setGrabSt(null)}}
       onMouseEnter={(e)=>{inputRef.current.checked=true}} 
      >
        
          

       
        <label style={{bottom:'auto'}} htmlFor="side-menu-switch2"  >
        <img src={root+'/images/arrow.png' }  alt="arrow" style={{ width:'100%',verticalAlign:'middle'}}  ></img>
        {myCtx.getAllClipeBoard().length!==0 &&<>  
        <img src={root+'/images/hook.svg' }  alt="NO"  style={{width:35,padding:2}} ></img>
< span className="hook" >{myCtx.getAllClipeBoard().length }</span></>}
        </label>
        <div className="partslist">
        {myCtx.getAllClipeBoard().map((Item, indix) => (<div className="SeletedPart"  id={indix} key={indix} > 

<div   className="name SeletedPart"style={Item.updated_at==="not" ? {color:'orange','fontStyle':'italic'}:{overflow: 'hidden'} }>{Item.name}

 </div>
 
  <img src={root+'/images/'+Item.picFile }  className="linkImages" onError={imageOnErrorHandler} alt="linkImages" onClick={()=>{onSelect(Item)}} /> 
 <input className="itemWidget"  type="checkbox" style={{right:160}}  onClick={()=>{Item.updated_at==="not"?Item.updated_at=null: Item.updated_at="not" ;myCtx.setGrabSt ("checkbox");myCtx.geoUpdate() }}></input>

</div>))}


       { < GetSearch  onClick={onClick} myCtx={myCtx} ref={ref}/> }
</div>
       
       </span>
              </div>
    
        </>
  );
}
export default Navbar;


//function GetSearch({onClick,search}){
const GetSearch= React.forwardRef(({onClick,myCtx},ref )=>{

  const [update,setUpdate]=useState(false ) 
  ref.current={Update:()=>{}}
  ref.current.Update=function(){
    setUpdate(!update)}

  const { error, isPending, data} = useFetch(root+'/api/Search/'+(myCtx.getSearch()==="All"?null:myCtx.getSearch()))
 
  //()=>{onClick() history.push("/api/Ass/" + Item.id)},
  //()=>{onClick( Item.id) history.push("/api/Ass/" +)},
  return (<>        
{error && <div>Sorry, no shearch results found</div>}
{isPending && <div>Loading...</div>}
{data && 
data.map((Item, indix) => (<div className="SeletedPart"  id={indix} key={indix} onClick={()=>{onClick(Item.id)}}> 

<div className="title"style={Item.updated_at==="not" ? {color:'orange','fontStyle':'italic'}:{overflow: 'hidden'} }>{Item.name} </div>
<div className="content" style={Item.updated_at==="not" ? {color:'orange','fontStyle':'italic'}:{overflow: 'hidden'} }>
<div >Part Number:{Item.id}:{Item.id} </div>
<div className="date"  >{Item.updated_at==="not"?"Not Saved!" :new Date(Item.updated_at).toISOString().split('T')[0]} </div>
<div className="author" >Theodros Galt</div>
<div className="rating" > $  </div>
<img src={root+'/images/'+Item.picFile }  className="linkImages"  alt="linked" onError={imageOnErrorHandler} /> 
</div>
</div>
))
};
</>)
})




function imageOnErrorHandler(e){
  e.currentTarget.src = ('./icon/Fallback.svg')
 } 